import React, { Component } from "react";

import pic6 from "../../assets/images/about/repair.png";
import { withTranslation } from "react-i18next";

class ChooseUsSection extends Component {
  render() {
    return (
      <>
        <section className="section-area bg-white section-sp1">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 mb-30">
                <div className="choose-media">
                  <img src={pic6} alt="" />
                </div>
              </div>
              <div className="col-lg-6 mb-30">
                <div className="heading-bx mb-30">
                  <h2 className="title mb-0 uppercase">
                    {this.props.t("whyChooseUs")}
                  </h2>
                  <p>{this.props.t("whyChooseUsDescription")}</p>
                </div>
                <div className="progress-area">
                  <div className="progress-box style1 appear">
                    <div className="progress-info">
                      <h6 className="title">
                        {this.props.t("servicesQuality")}
                      </h6>
                      <h6 className="progress-value">80%</h6>
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar bg-primary"
                        style={{ width: "80%" }}
                      ></div>
                    </div>
                  </div>
                  <div className="progress-box style1 appear">
                    <div className="progress-info">
                      <h6 className="title">
                        {this.props.t("experiencesEngineers")}
                      </h6>
                      <h6 className="progress-value">85%</h6>
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar bg-primary"
                        style={{ width: "85%" }}
                      ></div>
                    </div>
                  </div>
                  <div className="progress-box style1 appear">
                    <div className="progress-info">
                      <h6 className="title">
                        {this.props.t("longTermWarranty")}
                      </h6>
                      <h6 className="progress-value">70%</h6>
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar bg-primary"
                        style={{ width: "70%" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withTranslation()(ChooseUsSection);
