import React from "react";
import { Link } from "react-router-dom";

import Header from "../components/header";
import Footer from "../components/footer";

import bnrImg from "../assets/images/garage/pic3.jpg";
import { useTranslation } from "react-i18next";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import { BookingsService } from "../services/bookings.service";

const FormSchema = Yup.object().shape({
  make: Yup.string().trim().required("mandatoryField"),
  year: Yup.string().trim().required("mandatoryField"),
  name: Yup.string().trim().required("mandatoryField"),
  phone: Yup.string().required("mandatoryField").min(9),
  service: Yup.string().trim().required("mandatoryField"),
  email: Yup.string().email("invalidEmail"),
});

const Booking = () => {
  const { t } = useTranslation();
  const bookingsService = new BookingsService();

  const onMessageSubmit = (values) => {
    bookingsService.addBooking(values).then(() => (window.location.href = "/"));
  };

  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bnrImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry text-center">
              <h1>
                <span>{t("booking")}</span>
              </h1>
              <nav aria-label="breadcrumb" className="breadcrumb-row">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="las la-home"></i>
                      {t("home")}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("booking")}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <section className="section-area section-sp1">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-7 mb-30 mb-md-50">
                <Formik
                  onSubmit={onMessageSubmit}
                  validationSchema={FormSchema}
                  initialValues={{
                    make: "",
                    year: undefined,
                    name: "",
                    phone: "",
                    service: "",
                    email: "",
                  }}
                >
                  {({ setFieldValue, errors, touched, values }) => {
                    return (
                      <Form className="booking-form">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <h6 className="form-title uppercase">
                                {t("modelMarca")}
                              </h6>
                              <div className="input-group">
                                <input
                                  required
                                  type="text"
                                  className="form-control"
                                  placeholder="ex. BMW 5"
                                  value={values.make}
                                  onChange={(e) =>
                                    setFieldValue("make", e.target.value)
                                  }
                                />
                                {errors.make && touched.make && (
                                  <small className="p-invalid">
                                    {t("mandatoryField")}
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <h6 className="form-title uppercase">
                                {t("anulFabricarii")}
                              </h6>
                              <div className="input-group">
                                <input
                                  type="number"
                                  className="form-control"
                                  value={values.year}
                                  placeholder="ex. 2015"
                                  onChange={(e) =>
                                    setFieldValue("year", e.target.value)
                                  }
                                />
                                {errors.year && touched.year && (
                                  <small className="p-invalid">
                                    {t("mandatoryField")}
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <h6 className="form-title uppercase">
                                {t("personalInfo")}
                              </h6>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <div className="input-group">
                                <input
                                  name="name"
                                  type="text"
                                  className="form-control"
                                  placeholder={t("numePrenume")}
                                  required
                                  value={values.name}
                                  onChange={(e) =>
                                    setFieldValue("name", e.target.value)
                                  }
                                />
                                {errors.name && touched.name && (
                                  <small className="p-invalid">
                                    {t("mandatoryField")}
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <div className="input-group">
                                <input
                                  name="email"
                                  type="email"
                                  placeholder={t("email")}
                                  className="form-control"
                                  required
                                  value={values.email}
                                  onChange={(e) =>
                                    setFieldValue("email", e.target.value)
                                  }
                                />
                                {errors.email && touched.email && (
                                  <small className="p-invalid">
                                    {t("mandatoryField")}
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <div className="input-group">
                                <input
                                  name="phone"
                                  type="text"
                                  placeholder={t("phone")}
                                  className="form-control"
                                  required
                                  value={values.phone}
                                  onChange={(e) =>
                                    setFieldValue("phone", e.target.value)
                                  }
                                />
                                {errors.phone && touched.phone && (
                                  <small className="p-invalid">
                                    {t("mandatoryField")}
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <div className="input-group">
                                <textarea
                                  name="service"
                                  rows="4"
                                  className="form-control"
                                  required
                                  placeholder={t("service")}
                                  value={values.service}
                                  onChange={(e) =>
                                    setFieldValue("service", e.target.value)
                                  }
                                ></textarea>
                                {errors.service && touched.service && (
                                  <small className="p-invalid">
                                    {t("mandatoryField")}
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <button
                              name="submit"
                              type="submit"
                              value="Submit"
                              className="btn btn-primary btn-lg"
                            >
                              {" "}
                              {t("booking")}
                            </button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
              <div className="col-lg-4 col-md-5 mb-30">
                <aside className="sticky-top">
                  <div className="widget">
                    <ul className="service-menu style-1">
                      <li>
                        <Link to="/about-us">
                          {t("aboutUs")}{" "}
                          <i className="las la-question-circle"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/services">
                          {t("ourServices")} <i className="las la-cog"></i>
                        </Link>
                      </li>
                      <li className="active">
                        <Link to="/booking">
                          {t("booking")}
                          <i className="lab la-wpforms"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default Booking;
