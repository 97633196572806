import React, { Component } from "react";
import { Link } from "react-router-dom";

import logoWhite from "../assets/images/logo-white.png";
import icon1 from "../assets/images/contact/icon1.png";
import icon2 from "../assets/images/contact/icon2.png";
import icon3 from "../assets/images/contact/icon3.png";
import { withTranslation } from "react-i18next";

class Footer extends Component {
  render() {
    return (
      <>
        <footer className="footer">
          <div className="footer-info bg-primary">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-6 col-sm-6 mb-30">
                  <div className="footer-logo">
                    <img src={logoWhite} alt="" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 mb-30">
                  <div className="feature-container left footer-info-bx">
                    <div className="icon-lg">
                      <span className="icon-cell">
                        <img src={icon3} alt="" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <p>Mihai Viteazu 27, Chisinau, Moldova</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 mb-30">
                  <div className="feature-container left footer-info-bx">
                    <div className="icon-lg">
                      <span className="icon-cell">
                        <img src={icon1} alt="" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <p>
                        <a className="phone-call" href="tel:+37368947392">
                          +373 (68) 947392
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 mb-30">
                  <div className="feature-container left footer-info-bx">
                    <div className="icon-lg">
                      <span className="icon-cell">
                        <img src={icon2} alt="" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <p>contact@sm-detailing.md</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-start">
                  <p className="mb-0">
                    © Copyright Serfix 2023. All right reserved.
                  </p>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-end">
                  <ul className="widget-link">
                    <li>
                      <Link to="/">
                        <span>{this.props.t("home")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/about-us">
                        <span>{this.props.t("aboutUs")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/services">
                        <span>{this.props.t("services")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us">
                        <span>{this.props.t("contactUs")}</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default withTranslation()(Footer);
