/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Header from "../components/header";
import Footer from "../components/footer";

import bnrImg from "../assets/images/garage/pic3.jpg";
import { useTranslation } from "react-i18next";

import { BookingsService } from "../services/bookings.service";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";

const BookingList = () => {
  const [bookings, setBookings] = useState();

  const { t } = useTranslation();
  const bookingsService = new BookingsService();

  useEffect(() => {
    getBookings();
  }, []);

  const getBookings = () => {
    bookingsService.getAllBookings().then((response) => setBookings(response));
  };

  const deleteBooking = (id) => {
    bookingsService.deleteBooking(id).then(() => {
      getBookings();
    });
  };

  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bnrImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry text-center">
              <h1>
                <span>{t("bookings")}</span>
              </h1>
              <nav aria-label="breadcrumb" className="breadcrumb-row">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="las la-home"></i>
                      {t("home")}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("bookings")}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <section className="section-area section-sp1">
          <div className="container">
            <div className="row">
              {bookings ? (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{t("numePrenume")}</th>
                      <th>{t("email")}</th>
                      <th>{t("phone")}</th>
                      <th>{t("marcaMasinii")}</th>
                      <th>{t("anulFabricarii")}</th>
                      <th>{t("service")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookings.map((booking, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{booking.name || ""}</td>
                        <td>{booking.email || ""}</td>
                        <td>
                          <a href={`tel:${booking.phone}`}>{booking.phone}</a>
                        </td>
                        <td>{booking.make}</td>
                        <td>{booking.year}</td>
                        <td>{booking.service}</td>
                        <td>
                          <div className="booking-close">
                            <i
                              className="ti-close"
                              onClick={() => deleteBooking(booking.id)}
                            ></i>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div className="flex-container">
                  <Spinner animation="border" />
                </div>
              )}
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default BookingList;
