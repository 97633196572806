/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import * as Yup from "yup";
import logo from "../assets/images/logo.png";
import { AuthService } from "../services/auth.service";
import { auth } from "../firebase/firebase-config";
import { onAuthStateChanged } from "firebase/auth";
import { Redirect } from "react-router-dom";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

const FormSchema = Yup.object().shape({
  email: Yup.string().email("invalidEmail").required("mandatoryField"),
  password: Yup.string().trim().required("mandatoryField"),
});

const Login = () => {
  const [user, setUser] = useState();

  const { t } = useTranslation();
  const authService = new AuthService();

  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });

  const onUserLogin = (values) => {
    authService.loginRequest(values.email, values.password);
  };

  if (user) return <Redirect to="/" />;

  return (
    <>
      <div className="page-wraper account-wraper bg-gray py-100 px-20">
        <div className="my-account">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <Formik
            onSubmit={onUserLogin}
            validationSchema={FormSchema}
            initialValues={{ email: "", password: "" }}
          >
            {({ setFieldValue, errors, touched, values }) => {
              return (
                <Form>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("email")}
                      value={values.email}
                      onChange={(e) => setFieldValue("email", e.target.value)}
                    />
                    {errors.email && touched.email && (
                      <small className="p-invalid">{t("mandatoryField")}</small>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder={t("password")}
                      value={values.password}
                      onChange={(e) =>
                        setFieldValue("password", e.target.value)
                      }
                    />
                    {errors.password && touched.password && (
                      <small className="p-invalid">{t("mandatoryField")}</small>
                    )}
                  </div>
                  <div className="form-group">
                    <button className="btn mb-10 btn-lg btn-primary w-100">
                      {t("login")}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default Login;
