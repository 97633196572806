import React, { Component } from "react";
import { Link } from "react-router-dom";

// Images
import bg2 from "../../assets/images/background/bg2.png";
import { withTranslation } from "react-i18next";

class ServicesSection extends Component {
  render() {
    return (
      <>
        <section
          className="section-area bg-primary service-wraper1 section-sp2"
          style={{
            backgroundImage: "url(" + bg2 + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right top",
          }}
        >
          <div className="container">
            <div className="heading-bx text-white d-lg-flex d-md-block align-items-end justify-content-between">
              <div className="clearfix">
                <h6 className="title-ext uppercase">
                  {this.props.t("ourServices")}
                </h6>
                <h2 className="title mb-0 uppercase">
                  {this.props.t("bestServices")}
                </h2>
              </div>
              <div className="clearfix mt-md-20">
                <Link to="/services" className="btn btn-outline-light">
                  {this.props.t("viewAllServices")}
                </Link>
              </div>
            </div>
            <div className="row spno">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="feature-container bg-gray-alt feature-bx1 mb-sm-30">
                  <div className="icon-lg mb-10 text-primary">
                    <i className="flaticon-paint"></i>
                  </div>
                  <div className="icon-content">
                    <h5 className="ttr-tilte uppercase">
                      {this.props.t("detailing")}
                    </h5>
                    <p>{this.props.t("multipleDetailingServices")}</p>
                    <Link to="/service-detailing" className="btn-link">
                      {this.props.t("readMore")}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="feature-container feature-bx1 mb-sm-30">
                  <div className="icon-lg mb-10 text-primary">
                    <i className="flaticon-car-2"></i>
                  </div>
                  <div className="icon-content">
                    <h5 className="ttr-tilte uppercase">
                      {this.props.t("electric")}
                    </h5>
                    <p>{this.props.t("electricServices")}</p>
                    <Link to="/service-electric" className="btn-link">
                      {this.props.t("readMore")}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="feature-container bg-gray-alt feature-bx1 mb-sm-30">
                  <div className="icon-lg mb-10 text-primary">
                    <i className="flaticon-wash"></i>
                  </div>
                  <div className="icon-content">
                    <h5 className="ttr-tilte uppercase">
                      {this.props.t("carWash")}
                    </h5>
                    <p>{this.props.t("carWashServices")}</p>
                    <Link to="/service-car-wash" className="btn-link">
                      {this.props.t("readMore")}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="feature-container feature-bx1 mb-sm-30">
                  <div className="icon-lg mb-10 text-primary">
                    <i className="flaticon-car-7"></i>
                  </div>
                  <div className="icon-content">
                    <h5 className="ttr-tilte uppercase">
                      {this.props.t("fonicIzolation")}
                    </h5>
                    <p>{this.props.t("fonicizolationServices")}</p>
                    <Link to="/service-soundproofing" className="btn-link">
                      {this.props.t("readMore")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withTranslation()(ServicesSection);
