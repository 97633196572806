import React, { Component } from "react";
import Slider from "react-slick";

import welcomeBackground from "../../assets/images/welcome/welcome-background.jpg";
import welcomeWheel from "../../assets/images/welcome/welcome-wheel.png";
import welcomeCar from "../../assets/images/welcome/welcome-car.png";

import { withTranslation } from "react-i18next";

class WelcomeSection extends Component {
  render() {
    return (
      <>
        <div className="ttr-slider style-2">
          <Slider className="ttr-swiper01">
            <div className="slider-item">
              <div className="slider-img">
                <img src={welcomeBackground} alt="" />
              </div>
              <div className="slider-content container text-center text-white">
                <div className="content-inner">
                  <h6 className="sub-title">{this.props.t("bestOf")}</h6>
                  <h2 className="title">SM Detailing</h2>
                  <div className="car-wheel wheel2" data-swiper-parallax="-600">
                    <img src={welcomeCar} alt="" />
                    <div className="wheel-1">
                      <img src={welcomeWheel} alt="" />
                    </div>
                    <div className="wheel-2">
                      <img src={welcomeWheel} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </>
    );
  }
}

export default withTranslation()(WelcomeSection);
