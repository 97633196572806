import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "../components/header";
import Footer from "../components/footer";

import bnrImg from "../assets/images/garage/pic5.jpg";
import { withTranslation } from "react-i18next";

class ContactUs extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div
            className="page-banner ovbl-dark"
            style={{ backgroundImage: "url(" + bnrImg + ")" }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>
                  <span>{this.props.t("contactUs")}</span>
                </h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="las la-home"></i>
                        {this.props.t("home")}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {this.props.t("contactUs")}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          <section className="section-area section-sp1">
            <div className="container">
              <div className="heading-bx mb-20">
                <h6 className="title-ext text-primary">
                  {this.props.t("contactInfo")}
                </h6>
                <h2 className="title mb-0 uppercase">
                  {this.props.t("contactUsMoreDetails")} <br />
                  {this.props.t("moreInfo")}
                </h2>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="icon-box">
                    <h6 className="title">
                      <i className="ti-map-alt"></i>
                      {this.props.t("location")}
                    </h6>
                    <p>Mihai Viteazu 27, Chisinau, Moldova</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="icon-box">
                    <h6 className="title">
                      <i className="ti-id-badge"></i>
                      {this.props.t("emailAndPhone")}
                    </h6>
                    <Link to="#">contact@sm-detailing.md</Link>
                    <p>
                      <a href="tel:+37368947392">+373 (68) 947392</a>
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="icon-box">
                    <h6 className="title">
                      <i className="ti-world"></i>
                      {this.props.t("followUs")}
                    </h6>
                    <ul className="social-media">
                      <li>
                        <a
                          target="_blank"
                          href="https://www.facebook.com/scn.electric"
                          rel="noreferrer"
                          className="btn btn-primary"
                        >
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://instagram.com/sm_electric_md"
                          rel="noreferrer"
                          className="btn btn-primary"
                        >
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="section-area">
            <iframe
              src="https://maps.google.com/maps?q=Mihai%20Viteazu%2027&t=&z=15&ie=UTF8&iwloc=&output=embed"
              className="align-self-stretch d-flex"
              style={{ width: "100%", minHeight: "650px" }}
              title="map"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default withTranslation()(ContactUs);
