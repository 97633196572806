import React, { Component } from "react";

import bg1 from "../../assets/images/background/bg1.png";
import aboutCar from "../../assets/images/about/about-car.png";
import { withTranslation } from "react-i18next";

class OurServices extends Component {
  render() {
    return (
      <>
        <section
          className="section-area section-sp3 about-wraper2 bg-gray"
          style={{
            backgroundImage: "url(" + bg1 + ")",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-sm-12 col-12 text-center">
                <div className="heading-bx text-center">
                  <h6 className="title-ext text-primary">
                    {this.props.t("aboutUs")}
                  </h6>
                  <h2 className="title mb-0 uppercase">
                    {this.props.t("weAreTalented")}
                  </h2>
                </div>
                <div className="about-content text-center">
                  <div className="ttr-media">
                    <img src={aboutCar} alt="" />
                  </div>
                  <div className="content-info">
                    <div className="content-left">
                      <div className="service-content">
                        <h6 className="title">{this.props.t("detailing")}</h6>
                      </div>
                      <div className="service-content">
                        <h6 className="title">{this.props.t("electric")}</h6>
                      </div>
                      <div className="service-content">
                        <h6 className="title">{this.props.t("carWash")}</h6>
                      </div>
                    </div>
                    <div className="content-right">
                      <div className="service-content">
                        <h6 className="title">
                          {this.props.t("fonicIzolation")}
                        </h6>
                      </div>
                      <div className="service-content">
                        <h6 className="title">
                          {this.props.t("ambientalLights")}
                        </h6>
                      </div>
                      <div className="service-content">
                        <h6 className="title">{this.props.t("roofLights")}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withTranslation()(OurServices);
