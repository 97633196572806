import React from "react";
import Layout from "./components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "react-modal-video/css/modal-video.min.css";

import "./vendor/line-awesome/css/line-awesome.min.css";
import "./vendor/font-awesome/css/font-awesome.min.css";
import "./vendor/flaticon/flaticon.css";
import "./vendor/themify/themify-icons.css";

import "./scss/App.scss";

import "./translations/config/i18n";

function App() {
  return (
    <div className="page-wraper">
      <Layout />
    </div>
  );
}

export default App;
