import {
  collection,
  addDoc,
  getDocs,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebase-config";

export class BookingsService {
  bookingsCollectionRef = collection(db, "bookings");

  addBooking = async (booking) => {
    await addDoc(this.bookingsCollectionRef, {
      ...booking,
    });
  };

  getAllBookings = async () => {
    const data = await getDocs(this.bookingsCollectionRef);
    return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  };

  deleteBooking = async (id) => {
    const bookingDoc = doc(db, "bookings", id);
    await deleteDoc(bookingDoc);
  };
}
