/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import BackToTop from "./back-top";
import PageScrollTop from "../components/page-scroll-top";

import Landing from "../pages/landing";
import Booking from "../pages/booking";
import ContactUs from "../pages/contact-us";
import AboutUs from "../pages/about-us/";
import Services from "../pages/services/";

import Login from "../pages/login";
import Register from "../pages/register";
import ServiceDetailing from "../pages/services/service-detailing";
import ServiceElectric from "../pages/services/service-electric";
import ServiceCarWash from "../pages/services/service-car-wash";
import ServiceSoundproofing from "../pages/services/service-soundproofing";
import Error from "../pages/error-404";
import { auth } from "../firebase/firebase-config";
import BookingList from "../pages/booking-list";

const Markup = () => {
  const [isAuthenticated, setIsAuthentcated] = useState();

  onAuthStateChanged(auth, (currentUser) => {
    setIsAuthentcated(!!currentUser);
  });

  return (
    <>
      {typeof isAuthenticated === "boolean" && (
        <BrowserRouter basename={"/"}>
          <Switch>
            <Route path="/" exact component={Landing} />
            <Route path="/login" exact component={Login} />
            <Route path="/register" exact component={Register} />
            <Route path="/about-us" exact component={AboutUs} />
            <Route path="/services" exact component={Services} />
            <Route path="/booking" exact component={Booking} />
            {isAuthenticated && (
              <Route path="/bookings" exact component={BookingList} />
            )}
            <Route path="/contact-us" exact component={ContactUs} />
            <Route
              path="/service-detailing"
              exact
              component={ServiceDetailing}
            />
            <Route path="/service-electric" exact component={ServiceElectric} />
            <Route path="/service-car-wash" exact component={ServiceCarWash} />
            <Route
              path="/service-soundproofing"
              exact
              component={ServiceSoundproofing}
            />
            <Route component={Error} />
          </Switch>

          <PageScrollTop />
        </BrowserRouter>
      )}

      <BackToTop />
    </>
  );
};

export default Markup;
