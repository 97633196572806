import React, { Component } from "react";
import { Link } from "react-router-dom";

import bg1 from "../../assets/images/background/bg1.png";
import pic1 from "../../assets/images/garage/pic5.jpg";
import { withTranslation } from "react-i18next";

class AboutUsSection extends Component {
  render() {
    return (
      <>
        <section
          className="section-area section-sp1 bg-white"
          style={{
            backgroundImage: "url(" + bg1 + ")",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 mb-40">
                <div className="heading-bx mb-4">
                  <h6 className="title-ext text-primary">
                    {this.props.t("aboutUs")}
                  </h6>
                  <h2 className="title uppercase">
                    {this.props.t("weAreTalented")}
                  </h2>
                  <p>{this.props.t("aboutUsText")}</p>
                </div>
                <Link to="/about-us" className="btn btn-primary">
                  {this.props.t("aboutUs")}
                </Link>
              </div>
              <div className="col-lg-7 mb-30">
                <div className="about-media">
                  <div className="media">
                    <img src={pic1} alt="" />
                  </div>
                  <div className="about-contact bg-primary text-white">
                    <h6 className="title-ext text-white uppercase">
                      {this.props.t("wantToTalk")}
                    </h6>
                    <h3 className="number mb-15 text-white">
                      <a className="phone-call" href="tel:+37368947392">
                        +373 (68) 947392
                      </a>
                    </h3>
                    <p className="mb-20">
                      {this.props.t("needSpecialService")}
                    </p>
                    <Link to="/booking" className="btn btn-light">
                      {this.props.t("booking")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withTranslation()(AboutUsSection);
