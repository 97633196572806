import React, { Component } from "react";

import Footer from "../../components/footer";

import Header from "../../components/header";
import WelcomeSection from "./welcome-section";
import AboutUsSection from "./about-us-section";
import ServicesSection from "./services-section";
import ChooseUsSection from "./choose-us-section";

class Landing extends Component {
  render() {
    return (
      <>
        <Header />

        <WelcomeSection />
        <AboutUsSection />
        <ServicesSection />
        <ChooseUsSection />

        <Footer />
      </>
    );
  }
}

export default Landing;
