import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "../../components/header";
import Footer from "../../components/footer";

import bnrImg from "../../assets/images/garage/pic5.jpg";
import Ceramica from "../../assets/images/detailing/ceramica.jpg";
import Chimica from "../../assets/images/detailing/chimica2.jpg";
import Polish1 from "../../assets/images/detailing/polish1.jpg";
import Polish2 from "../../assets/images/detailing/polish2.jpg";
import { withTranslation } from "react-i18next";

class ServiceDetailing extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div
            className="page-banner ovbl-dark"
            style={{ backgroundImage: "url(" + bnrImg + ")" }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>
                  <span>{this.props.t("detailing")}</span>
                </h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="las la-home"></i>
                        {this.props.t("home")}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {this.props.t("detailing")}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          <div className="section-area section-sp1 bg-white">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-7">
                  <div className="row mb-15">
                    <div className="col-md-6 mb-15">
                      <div className="ttr-media">
                        <img src={Ceramica} className="imghight" alt="" />
                      </div>
                    </div>
                    <div className="col-md-6 mb-15">
                      <div className="ttr-media">
                        <img src={Chimica} className="imghight" alt="" />
                      </div>
                    </div>
                    <div className="col-md-6 mb-15">
                      <div className="ttr-media">
                        <img src={Polish1} className="imghight" alt="" />
                      </div>
                    </div>
                    <div className="col-md-6 mb-15">
                      <div className="ttr-media">
                        <img src={Polish2} className="imghight" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="mb-20">
                    <div className="head-text mb-30">
                      <h4 className="title mb-10 uppercase">
                        {this.props.t("detailingServices")}
                      </h4>
                      <p className="mb-0">
                        {this.props.t("detailingDescription")}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="head-text mb-30">
                        <h4 className="title mb-10 uppercase">
                          {this.props.t("interior")}
                        </h4>
                      </div>
                      <ul className="list-check-squer mb-0">
                        <li>
                          {this.props.t("curatareChimica")} (
                          {this.props.t("from")} 100€)
                        </li>
                        <li>
                          {this.props.t("hidratarePiele")} (
                          {this.props.t("from")} 50€)
                        </li>
                        <li>
                          {this.props.t("protectieCeramicaPiele")} (
                          {this.props.t("from")} 80€)
                        </li>
                        <li>
                          {this.props.t("protectieCeramicaPlasticuri")} (
                          {this.props.t("from")} 70€)
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <div className="head-text mb-30">
                        <h4 className="title mb-10 uppercase">
                          {this.props.t("exterior")}
                        </h4>
                      </div>
                      <ul className="list-check-squer mb-0">
                        <li>
                          {this.props.t("polisareCaroserie")} (
                          {this.props.t("from")} 150€)
                        </li>
                        <li>
                          {this.props.t("protectieCeramica")} (
                          {this.props.t("from")} 200€)
                        </li>
                        <li>
                          {this.props.t("reconditionareFaruri")} (
                          {this.props.t("from")} 100€)
                        </li>
                        <li>
                          {this.props.t("colantareOrnamente")} (
                          {this.props.t("from")} 200€)
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-5">
                  <aside className="sticky-top pb-1">
                    <div className="widget">
                      <ul className="service-menu">
                        <li className="active">
                          <Link to="/service-detailing">
                            <span>{this.props.t("detailing")}</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/service-electric">
                            <span>{this.props.t("electric")}</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/service-car-wash">
                            <span>{this.props.t("carWash")}</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/service-soundproofing">
                            <span>{this.props.t("fonicIzolation")}</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default withTranslation()(ServiceDetailing);
