import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth } from "../firebase/firebase-config";

export class AuthService {
  loginRequest = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.log(error);
    }
  };

  registerRequest = async (email, password) => {
    return await createUserWithEmailAndPassword(auth, email, password);
  };

  logoutRequest = async () => {
    signOut(auth).then(() => (window.location.pathname = "/login"));
  };
}
