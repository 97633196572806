import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "../../components/header";
import Footer from "../../components/footer";

import bnrImg from "../../assets/images/garage/pic4.jpg";
import { withTranslation } from "react-i18next";

class ServiceCarWash extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div
            className="page-banner ovbl-dark"
            style={{ backgroundImage: "url(" + bnrImg + ")" }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>
                  <span>{this.props.t("carWash")}</span>
                </h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="las la-home"></i>
                        {this.props.t("home")}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {this.props.t("carWash")}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          <div className="section-area section-sp1 bg-white">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-7">
                  <div className="head-text mb-30">
                    <h4 className="title mb-10 uppercase">
                      {this.props.t("carWash")}
                    </h4>
                    <p className="mb-0">{this.props.t("carwashDescription")}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-5">
                  <aside className="sticky-top pb-1">
                    <div className="widget">
                      <ul className="service-menu">
                        <li>
                          <Link to="/service-detailing">
                            <span>{this.props.t("detailing")}</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/service-electric">
                            <span>{this.props.t("electric")}</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li className="active">
                          <Link to="/service-car-wash">
                            <span>{this.props.t("carWash")}</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/service-soundproofing">
                            <span>{this.props.t("fonicIzolation")}</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default withTranslation()(ServiceCarWash);
