import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";

import roFlag from "../assets/images/ro.png";
import ruFlag from "../assets/images/ru.png";
import logo from "../assets/images/logo.png";
import logoWhite from "../assets/images/logo-white.png";
import { withTranslation } from "react-i18next";
import { auth } from "../firebase/firebase-config";

class Header extends Component {
  componentDidMount() {
    var menuIcon = document.querySelector(".menuicon");
    var menuLinks = document.querySelector(".menu-links");
    var menuClose = document.getElementById("menuClose");

    menuIcon.addEventListener("click", function () {
      menuLinks.classList.add("show");
    });

    menuClose.addEventListener("click", function () {
      menuLinks.classList.remove("show");
    });

    var navMenu = [].slice.call(
      document.querySelectorAll(".menu-links > ul > li")
    );
    for (var y = 0; y < navMenu.length; y++) {
      navMenu[y].addEventListener("click", function () {
        menuClick(this);
      });
    }

    function menuClick(current) {
      const active = current.classList.contains("open");
      navMenu.forEach((el) => el.classList.remove("open"));

      if (active) {
        current.classList.remove("open");
      } else {
        current.classList.add("open");
      }
    }
  }

  changeLanguage() {
    if (this.props.i18n.language === "ro") {
      this.props.i18n.changeLanguage("ru");
    } else {
      this.props.i18n.changeLanguage("ro");
    }
  }

  render() {
    return (
      <>
        <header className="header header-transparent rs-nav">
          <Sticky enabled={true} className="sticky-header navbar-expand-lg">
            <div className="menu-bar clearfix ">
              <div className="container clearfix">
                <div className="menu-logo">
                  <Link to="/" className="main-logo">
                    <img src={logoWhite} alt="" />
                  </Link>
                  <Link to="/" className="sticky-logo">
                    <img src={logo} alt="" />
                  </Link>
                </div>
                <button
                  className="navbar-toggler collapsed menuicon justify-content-end"
                  type="button"
                  data-toggle="collapse"
                  data-target="#menuDropdown"
                  aria-controls="menuDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <div className="secondary-menu">
                  {!auth.currentUser && (
                    <Link to="/booking" className="btn btn-primary ms-3">
                      {this.props.t("booking")}
                    </Link>
                  )}

                  {auth.currentUser && (
                    <Link to="/bookings" className="btn btn-primary ms-3">
                      {this.props.t("bookings")}
                    </Link>
                  )}
                  <img
                    className="language-icon"
                    src={this.props.i18n.language === "ro" ? ruFlag : roFlag}
                    onClick={() => this.changeLanguage()}
                    alt=""
                  />
                </div>
                <div
                  className="menu-links navbar-collapse collapse justify-content-center"
                  id="menuDropdown"
                >
                  <div className="menu-logo">
                    <Link to="/">
                      <img src={logoWhite} alt="" />
                    </Link>
                  </div>
                  <ul className="nav navbar-nav">
                    <li>
                      <Link to="/about-us">
                        <span>{this.props.t("aboutUs")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/services">
                        <span>{this.props.t("services")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us">
                        <span>{this.props.t("contactUs")}</span>
                      </Link>
                    </li>
                  </ul>

                  <ul className="social-media">
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/scn.electric"
                        rel="noreferrer"
                        className="btn btn-primary"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://instagram.com/sm_electric_md"
                        rel="noreferrer"
                        className="btn btn-primary"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    {!auth.currentUser && (
                      <li>
                        <Link to="/booking" className="btn btn-primary">
                          <i className="fa fa-calendar-plus-o"></i>
                        </Link>
                      </li>
                    )}
                    {auth.currentUser && (
                      <li>
                        <Link to="/bookings" className="btn btn-primary">
                          <i className="fa fa-calendar-o"></i>
                        </Link>
                      </li>
                    )}
                    <li>
                      <img
                        className="language-icon"
                        src={
                          this.props.i18n.language === "ro" ? ruFlag : roFlag
                        }
                        onClick={() => this.changeLanguage()}
                        alt=""
                      />
                    </li>
                    {!auth.currentUser && (
                      <li>
                        <Link to="/login" className="btn btn-primary ms-4">
                          <i className="fa fa-sign-in"></i>
                        </Link>
                      </li>
                    )}
                  </ul>
                  <div className="menu-close" id="menuClose">
                    <i className="ti-close"></i>
                  </div>
                </div>
              </div>
            </div>
          </Sticky>
        </header>
      </>
    );
  }
}

export default withTranslation()(Header);
