import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "../../components/header";
import Footer from "../../components/footer";

import OurServices from "./our-services";
import ChooseUsSection from "../../pages/landing/choose-us-section";

import bnrImg from "../../assets/images/garage/pic3.jpg";
import { withTranslation } from "react-i18next";

class AboutUs extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div
            className="page-banner ovbl-dark"
            style={{ backgroundImage: "url(" + bnrImg + ")" }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>
                  <span>{this.props.t("aboutUs")}</span>
                </h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="las la-home"></i>
                        {this.props.t("home")}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {this.props.t("aboutUs")}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          <OurServices />
          <ChooseUsSection />
        </div>

        <Footer />
      </>
    );
  }
}

export default withTranslation()(AboutUs);
