import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-config";

export class UsersService {
  usersCollectionRef = collection(db, "users");

  addUser = async (user, id) => {
    await setDoc(doc(db, "users", id), {
      ...user,
    });
  };
}
