import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAwIn7O71t1zvHOcBIpSujAr9EUd0h4OGk",
  authDomain: "sm-detailing.firebaseapp.com",
  databaseURL: "https://sm-detailing-default-rtdb.firebaseio.com",
  projectId: "sm-detailing",
  storageBucket: "sm-detailing.appspot.com",
  messagingSenderId: "416484689181",
  appId: "1:416484689181:web:cb3e329ee8032430c9cdf4",
  measurementId: "G-BZL4CF1GVS",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
