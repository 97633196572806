import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import ro from "../ro.json";
import ru from "../ru.json";

const resources = {
  ro,
  ru,
};

export const availableLanguages = Object.keys(resources);

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: localStorage.getItem("i18nextLng") || "ro",
    defaultNS: "translations",
    fallbackLng: "ro",
  });

export default i18n;
